<template>
    <el-dialog
    v-model="dialogVisible"
    :title="type?'编辑信息':'添加信息'"
    width="80%"
    >
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    size="large">
    <div class="fromTop">
        <el-form-item label="标题" prop="title">
            <el-input style="min-width: 260px;" v-model="ruleForm.title" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
            <el-tree-select 
             style="min-width: 260px;" 
             v-model="ruleForm.type"
             size="large"
             node-key="id"
             :props="defaultProps" 
             :data="dataTree" 
             :render-after-expand="false" />
        </el-form-item>
        <el-form-item label="创建者" prop="provider">
            <el-input  style="min-width: 260px;"  v-model="ruleForm.provider" />
        </el-form-item>
        <el-form-item label="来源" prop="sourcename">
            <el-input  style="min-width: 260px;"  v-model="ruleForm.sourcename" />
        </el-form-item>
    </div>
    <el-form-item label="概要" prop="summary">
        <el-input type="textarea"  v-model="ruleForm.summary" />
    </el-form-item>
    <el-form-item 
        label="封面"
        prop="iconUrl">
        <el-upload
        :show-file-list="false"
        action=""
        accept="image/*"
        :http-request="HttpRequest"
        :before-upload='BeforeUpload'
        >
            <img v-if="iconUrl" :src="iconUrl" class="avatar" />
            <el-button v-else size="large"  color="#803a15" dark > 上传</el-button>
            <template #tip>
            <div class="el-upload__tip">
                单个图片大小请勿超过5M
            </div>
            </template>
        </el-upload>
    </el-form-item>
    <el-form-item label="正文" prop="context">
        <div class="editor-box">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
        />
        <Editor
            style="height: 500px;overflow-y: hidden;"
            v-model="ruleForm.context"
            :defaultConfig="editorConfig"
            @onCreated="onCreated"
            @customPaste="customPaste"
        />
    </div>
    </el-form-item>
    </el-form>
    
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button  color="#803a15" dark  @click="formSubmit('ruleFormRef')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { res_update, res_add} from '@/api/resource.js'
//script标签中引入
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    components: { Editor, Toolbar },
    emits:['OK'],
    props:{
        dataTree:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    data(){
        const checkIconUrl = (rule, value, callback)=>{
            if (this.iconUrl) {
                callback()
            }else {
                callback(new Error('请上传封面'))
            }
        }
        return{
            defaultProps:{
                children: 'children',
                label: 'name',
            },
            dialogVisible:false,
            type:0,
            ruleForm:{
                id:null,
                title:'',
                provider:'',
                sourcename:'',
                type:'',
                context:'',
                iconUrl:null,
                summary:'',
            },
            iconUrl:'',
            rules:{
                title:{ required: true, message: '标题不能为空', trigger: 'blur' },
                provider:{ required: true, message: '创建者不能为空', trigger: 'blur' },
                sourcename:{ required: true, message: '来源不能为空', trigger: 'blur' },
                type:{ required: true, message: '类型不能为空', trigger: 'blur' },
                // context:{ required: true, message: '正文内容不能为空', trigger: 'blur' },
                iconUrl:{required: true, validator: checkIconUrl, trigger: 'blur' },
                summary:{required: true, message: '请填写概要', trigger: 'blur' }
            },

            editor: null,
            toolbarConfig: {     // 工具栏配置
                toolbarKeys:[   // 重新配置工具栏，显示哪些菜单，以及菜单的排序、分组。
                    "fontSize", // 字号
                    // "fontFamily", // 字体
                    // "color", // 颜色
                    "bold", // 加粗
                    "italic", // 斜体
                    "underline", // 下划线
                    "insertLink",// 插入链接
                    "bulletedList", // 无序列表
                    "numberedList", // 有序列表
                    // 对齐
                    {
                        key: "group-justify",
                        title: "对齐",
                        iconSvg:"<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                        menuKeys: ['justifyLeft',"justifyRight","justifyCenter","justifyJustify"]
                    },
                    "uploadImage",  // 上传图片
                ]
            },
            editorConfig: {      // 编辑器配置（图片如配置颜色、字体、链接校验、上传图片等）
                placeholder: '<p><span style="color: rgb(140, 140, 140); font-size: 16px; font-family: 微软雅黑;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从这里开始写正文...</span></p>',
                MENU_CONF:{
                    // 插入图片
                    uploadImage: {
 
                        base64LimitSize: 1024 * 1024 * 10 // 10MB 小于该值就插入 base64 格式（而不上传），默认为 0
                    },
                },
                mode: 'default', // 'default' or 'simple'
            }
        }
    },
    methods:{
        open(type,data){
            this.type = type
            this.dialogVisible = true
            if(type == 1){
                this.ruleForm = {
                    id:data.id,
                    title:data.title,
                    provider:data.provider,
                    sourcename:data.sourcename,
                    type:data.type,
                    context:data.context,
                    iconUrl:null,
                    summary:data.summary
                },
                this.iconUrl = data.cover
            }else{
                this.iconUrl = null
                this.$nextTick(()=>{
                    this.$refs.ruleFormRef.resetFields() 
                })
            }
            
        },
        formSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let fromData = new FormData()
                    fromData.append('title',this.ruleForm.title) 
                    fromData.append('provider',this.ruleForm.provider)
                    fromData.append('sourcename',this.ruleForm.sourcename)
                    fromData.append('type',this.ruleForm.type)
                    fromData.append('context',this.ruleForm.context)
                    fromData.append('summary', this.ruleForm.summary)
                    if(this.ruleForm.iconUrl){
                        fromData.append('iconUrl',this.ruleForm.iconUrl)
                    }
                    
                    if(this.type == 0){
                        this.AddData(fromData)
                    }else{
                        fromData.append('id',this.ruleForm.id)
                        this.EditData(fromData)
                    }
                } else {
                    return false;
                }
            });
        },
        AddData(data){
            res_add(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.$emit('OK',0)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '新增失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
        EditData(data){
            res_update(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.$emit('OK',1)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '编辑失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },

        // 编辑器创建完毕时的回调函数。
         onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
 
        // 对用户剪切板内容的处理
        customPaste(editor, event){
            // event 是 ClipboardEvent 类型，可以拿到粘贴的数据
            const text = event.clipboardData.getData('text/plain'); // 获取粘贴的纯文本
            editor.insertText(text);
            event.preventDefault(); // 阻止默认的粘贴行为
            return false;
        },

        HttpRequest(param){
            this.ruleForm.iconUrl = param.file
            this.iconUrl = URL.createObjectURL(param.file)
            this.$refs.ruleFormRef.validateField('iconUrl')
        },
        BeforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
            this.$message.warning('图片大小不能超过 5MB!');
            }
            return isLt2M;
        },
    }
}
</script>
<style lang="scss" scoped>
.editor-box{
     width: 100%;    // 配置编辑器宽度
     height: auto;
     margin: 0 40px 20px 0;
     border: 1px solid #dddddd;  // 编辑器外边框
}
.fromTop{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.avatar{
    width: 200px;
    height: 200px;
    object-fit: contain;
}
</style>